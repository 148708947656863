import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBrPde9wUj0LXAlw5ERbOS-SOzzwfceo9U",
    authDomain: "kyranis-36e93.firebaseapp.com",
    projectId: "kyranis-36e93",
    storageBucket: "kyranis-36e93.appspot.com",
    messagingSenderId: "876768912489",
    appId: "1:876768912489:web:c2eecebf83c4da4cdceef8"
  };

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore()
export const firebaseApp = firebase
